<template>
    <div class="crossPortList"> 
        <div class="padd20 borbut20" style="position: relative;">
            <div class="financiallistFrome active">
                <div class="topSearchSou">
                    <div class="searchdan">
                        <div class="title">转出账户名：</div>
                        <el-input v-model="out_account_name" placeholder="请输入转出账户名称"></el-input>
                    </div>
                    <div class="searchdan">
                        <div class="title">转出账户ID：</div>
                        <el-input v-model="out_account_id" placeholder="请输入转出账户ID"></el-input>
                    </div>
                    <div class="searchdan">
                        <div class="title">转入账户名：</div>
                        <el-input v-model="in_account_name" placeholder="请输入转入账户名称"></el-input>
                    </div>
                    <div class="searchdan">
                        <div class="title">转入账户ID：</div>
                        <el-input v-model="in_account_id" placeholder="请输入转入账户ID"></el-input>
                    </div>
                    <div class="searchdan time">
                        <div class="title">订单时间：</div>
                        <el-date-picker v-model="pickerTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </div>
                    <div class="searchdan">
                        <div class="title">转出广告币：</div>
                        <el-input v-model="balance" placeholder="请输入转出广告币"></el-input>
                    </div>
                    <div class="searchdan">
                        <div class="title">转出人民币：</div>
                        <el-input v-model="to_rmb" placeholder="请输入转出人民币"></el-input>
                    </div>
                    <div class="searchdan">
                        <div class="title">转入广告币：</div>
                        <el-input v-model="in_ad_money" placeholder="请输入转入广告币"></el-input>
                    </div>
                    
                </div>
            </div>
            <div class="RightBotton">
                <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right' style="margin-left:0;">重置数据</el-button>
            </div>
        </div>
        <div class="tableData padd20">
            <div class="duoshao">
                <div class="title">跨端口退款</div>
                <div class="lvtotal"><a class="download" @click="onExport" target="_blank">导出<i class="iconfont icon-xiazai"></i></a>总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="id" label="图片" width="100px">
                    <template slot-scope="scope">
                        <a :href="'//user.erlangcha.com/advances/Advimage?imageurl=' + scope.row.file_msg.file_url" target="_blank"><img class="corssImg" :src="scope.row.file_msg.file_url" alt=""></a>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="用户手机号">
                    <template slot-scope="scope">
                        {{scope.row.user_msg.account}}
                    </template>
                </el-table-column>
                <el-table-column prop="to_port_msg.advertiser_name" label="转出端口"></el-table-column>
                <el-table-column prop="out_account_id" label="转出账户ID"></el-table-column>
                <el-table-column prop="out_account_name" label="转出账户名称"></el-table-column>
                <el-table-column prop="to_rmb" label="转出人民币"></el-table-column>
                <el-table-column prop="to_port_rebates" label="转出返点"></el-table-column>
                <el-table-column prop="balance" label="转出广告币"></el-table-column>
                <el-table-column prop="in_port_msg.advertiser_name" label="转入端口"></el-table-column>
                <el-table-column prop="in_account_id" label="转入账户ID"></el-table-column>
                <el-table-column prop="in_account_name" label="转入账户名称"></el-table-column>
                <el-table-column prop="in_ad_money" label="转入广告币"></el-table-column>
                <el-table-column prop="in_port_rebates" label="转入返点"></el-table-column>
                <el-table-column prop="id" label="是否已关停转出账户">
                    <template slot-scope="scope">
                        {{scope.row.is_stop ? '是' : '否'}}
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="新增时间" width="160px"></el-table-column>
                <el-table-column prop="updated_at" label="更新时间" width="160px"></el-table-column>
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
        </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            paramData:{},//表格参数
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页
            tableData:[],//跨端口列表


            out_account_name:'',//转出账户名称  
            out_account_id:'',//转出账户ID
            in_account_name:'',//转入账户名称
            in_account_id:'',//转入账户ID 
            pickerTime:'',//时间
            balance:'',//转出广告币
            to_rmb:'',//转出人民币
            in_ad_money:'',//转入广告币
        };
    },

    mounted() {
        this.ongetPortTransferList()
    },

    methods: {
        //搜索
        onSearch(){
            this.paramData = {
                out_account_name:this.out_account_name,//转出账户名称  
                out_account_id:this.out_account_id,//转出账户ID
                in_account_name:this.in_account_name,//转入账户名称
                in_account_id:this.in_account_id,//转入账户ID 
                balance:this.balance,//转出广告币
                to_rmb:this.to_rmb,//转出人民币
                in_ad_money:this.in_ad_money,//转入广告币
                start_created_at: this.pickerTime ? this.pickerTime[0] : "", //开始日期
                end_created_at: this.pickerTime ? this.pickerTime[1] : "", //结束日期
            }
            this.ongetPortTransferList()
        },
        //导出
        onExport(){
            var UrlParam = '?export=1' + 
                            '&start_created_at=' +(this.pickerTime ? this.pickerTime[0] :  "") +  //开始日期
                            '&end_created_at=' + ( this.pickerTime ? this.pickerTime[1]  :  "") + //结束日期
                            '&out_account_name=' + (this.out_account_name ? this.out_account_name : '') +
                            '&out_account_id=' + (this.out_account_id ? this.out_account_id : '') +
                            '&in_account_name=' + (this.in_account_name ? this.in_account_name : '') +
                            '&in_account_id=' + (this.in_account_id ? this.in_account_id : '') +
                            '&balance=' + (this.balance ? this.balance : '') +
                            '&to_rmb=' + (this.to_rmb ? this.to_rmb : '') +
                            '&in_ad_money=' + (this.in_ad_money ? this.in_ad_money : '') +
                           '&per_page=9999' +
                           '&token=' + JSON.parse(localStorage.getItem('TZ-USER')).admin_token 
            window.open(this.$api.getPortTransferList + UrlParam)
        },
        //跨端口列表list
        ongetPortTransferList(){
            this.tableData = [];
            var param = this.paramData
            param.page = this.paramPage
            param.per_page = 20
            this.$service.get(this.$api.getPortTransferList,param, (res)=> {
                if(res.code == '200'){
                    this.tableData = res.data.data
                    this.lvtotal = res.data.total
                }
            })
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetPortTransferList()
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.crossPortList{
    .topSearchSou{
        width: calc(100% - 150px);
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        .searchdan{
            &.time{
                
            }
        }
    }
    .tableData{
        .corssImg{
            width: 80px;
            height: 80px;
        }
    }
    .financiallistFrome{
        position: relative;
        display: flex;
        // align-items: center;
        height:40px;
        transition: .5s;
        &.active{
        transition: .5s;
            height: 80px;
        }
        .RightBotton{
            width: 150px;
        }
        .topSearchSou{
            overflow: hidden;
            .el-input{
                width: 160px;
            }
            .searchdan{
                margin-bottom: 10px;
            }
            .searchdan:first-child{
                margin-left: 20px;
            }
        }
    }
}

</style>